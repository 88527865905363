var sendDate = new Date();

var GiftCard = new Vue({
    el: '#giftcard',
    data: {
        step: 'theme',
        loading: true,
        loadingMessage: 'loading',
        uid: null,
        giftcard: null,
        themes: null,
        debounceTimeout: null,
        minPrice: 5,
        maxPrice: 1000,
        showMax: false,
        showMin: false,
        uploader: null,
        picture: null,
        quality: 'good',
        send: "false",
        sendDelay: "false",
        modalPicture: false,
        pictureCropper: null,
        sitecontent: sitecontent,
        validation: [],
        messageLimit: 180,
        day: sendDate.getDate(),
        month: sendDate.getMonth() + 1,
        year: sendDate.getFullYear()
    },
    mounted: function(){
        if(typeof editVoucher !== 'undefined'){
            this.init();
        }
    },
    computed: {
        currentTheme: function() {
            if(!this.giftcard || !this.giftcard.theme_id || !this.themes) return false;
            for(var i = 0; i < this.themes.length;i++){
                if(this.giftcard.theme_id == this.themes[i].id) return this.themes[i];
            }
            return false;
        },
        date_days: function(){
            var days = [];
            for(i = 1; i <= new Date(this.year, this.month, 0).getDate(); i++){
                days.push(i);
            }
            return days;
        },
        date_months: function(){
            var months = [];
            for(i = 1; i <= 12; i++){
                months.push(i);
            }
            return months;
        },
        date_years: function(){
            var years = [2017,2018];
            return years;
        }
    },
    methods: {
        init: function(){
            this.themes = themes;
            this.uid = uid;
            this.step = 'theme';
            this.fetchCard(function(){
                if(this.giftcard.theme_id || this.giftcard.picture){
                    this.setStep('design');
                    if(this.validateDesign()) this.step = 'send'; this.initDatePicker();
                }
            }.bind(this));
        },
        uploadPicture: function(){
            if(this.giftcard.theme_id){
                return false;
            }
            this.loadingMessage = 'uploading';
            this.loading = true;
            $('#picture-upload').simpleUpload("/api/giftcards/"+this.uid+'/picture', {
                name: 'picture',
                limit: 1,
                expect: 'json',
                allowedExts: ['jpg','png','jpeg'],
                maxFileSize: 5000000,
                start: function(file){

                },
                progress: function(progress){

                }.bind(this),
                success: function(response){
                    this.giftcard.picture = response.picture;
                    this.giftcard.picture_link = response.picture_link;
                    Vue.nextTick(function () {
                        this.initCropper();
                    }.bind(this));
    			}.bind(this),
    			error: function(error){
                    if(error.name == "MaxFileSizeError"){
                        parseError('max_file_size')
                    }
                    this.loading = false;
    			}.bind(this)
            });
        },
        removePicture: function(){

            UIkit.modal.confirm(confirm_remove, function(){
                this.loadingMessage = 'removing';
                this.loading = true;
                $.ajax({
                    url: siteroot+'/api/giftcards/'+this.uid+'/picture',
                    method: 'DELETE'
                })
                .done(function(giftcard) {
                    this.giftcard.picture = null;
                    this.giftcard.picture_link = null;
                    this.pictureCropper = null;
                    this.loading = false;
                }.bind(this))
                .fail(function() {
                    this.loading = false;
                    parseError('delete_file')
                })
            }.bind(this));
        },
        focusMessage: function() {
            this.$refs.message.focus();
        },
        fetchCard: function(callback){
            if(!this.uid){
                return;
            }
            $.ajax({
                url: siteroot+'/api/giftcards/'+this.uid,
                method: 'GET'
            })
            .done(function(giftcard) {
                this.giftcard = giftcard;
                this.loading = false;
                this.parsePrice();
                this.findStep();
                if(typeof callback !== 'undefined'){
                    callback();
                }
            }.bind(this))
            .fail(function() {
                parseError('system');
            })
        },
        updateCard: function(callback) {
            if(!this.uid){
                return;
            }
            $.ajax({
                url: siteroot+'/api/giftcards/'+this.uid,
                method: 'PUT',
                data: this.giftcard
            })
            .done(function(giftcard) {
                this.giftcard = giftcard;
                this.parsePrice();
                if(callback){
                    callback();
                }
            }.bind(this))
            .fail(function(err) {
                if(callback){
                    callback(err);
                } else {
                    parseError('giftcard_save')
                }
            })
        },
        findStep: function() {
            if(this.giftcard.theme_id){
                this.step = 'design';
            }
        },
        setStep: function(step) {
            switch(step){
                case 'theme':
                    this.step = 'theme';
                    break;
                case 'design':
                    this.step = 'design';
                    break;
                case 'send':
                    if(!this.validateDesign()) return false;
                    this.loadingMessage = 'saving';
                    this.loading = true;
                    this.updateCard(function(err){
                        this.loading = false;
                        if(err){
                            parseError('giftcard_save')
                        } else {
                            this.step = "send";
                            this.initDatePicker();
                        }
                    }.bind(this))
                    break;
                default:
                    break;
            }
            Vue.nextTick(function () {
                this.sizePreview();
                window.scrollTo(0,0);
            }.bind(this));
        },
        initDatePicker: function() {

            var minDate = new Date();
            var maxDate = new Date();

            maxDate.setMonth(maxDate.getMonth() + 1);

            Vue.nextTick(function () {
                if(typeof this.$refs.datepicker !== 'undefined'){
                    var that = this;
                    this.datePicker = new Pikaday({
                        field: this.$refs.datepicker,
                        firstDay: 1,
                        minDate: minDate,
                        maxDate: maxDate,
                        format: 'DD/MM/YYYY',
                        keyboardInput: false,
                        toString: function(date, format) {
                            var day = ('0' + date.getDate()).slice(-2);
                            var month = ('0' + (date.getMonth()+1)).slice(-2);
                            var year = date.getFullYear();
                            return day + '/' + month + '/' + year;
                        },
                        parse: function(dateString, format) {
                            var parts = dateString.split('/');
                            if(parts.length != 3){
                                return new Date(that.year, that.month, that.day);
                            }
                            var day = parseInt(parts[0], 10);
                            var month = parseInt(parts[1] - 1, 10);
                            var year = parseInt(parts[2], 10);
                            if(day > 31 || month > 12 || year < 2017){
                                return new Date(that.year, that.month, that.day);
                            }
                            return new Date(year, month, day);
                        },
                        i18n: {
                            previousMonth : 'Vorige maand',
                            nextMonth     : 'Volgende maand',
                            months        : ['Januari','Februari','Maart','April','Mei','Juni','Juli','Augustus','September','Oktober','November','December'],
                            weekdays      : ['Zondag','Maandag','Dinsdag','Woensdag','Donderdag','Vrijdag','Zaterdag'],
                            weekdaysShort : ['Zo','Ma','Di','Wo','Do','Vr','Za']
                        },
                        onSelect: function() {
                            that.setDate(this.getDate());
                        }
                    });
                    this.datePicker.setDate(this.year+'-'+this.month+'-'+this.day);
                }
            }.bind(this))
        },
        setDate: function(date) {
            this.day = date.getDate();
            this.month = date.getMonth() + 1;
            this.year = date.getFullYear();
        },
        setTheme: function(theme) {
            if(!theme){
                this.giftcard.theme_id = null;
            } else {
                this.giftcard.theme_id = theme.id;
            }
            this.updateCard(function(err){
                if(!err){
                    this.setStep('design');
                }
            }.bind(this));
        },
        changeTheme: function(){
            this.setStep('theme');
        },
        parsePrice: function() {
            var price = parseInt(this.giftcard.price);
            if(isNaN(price) || !price ) price = null;
            if(price){
                if(price > this.maxPrice){
                    this.showMax = true;
                    var hide = setTimeout(function(){
                        this.showMax = false;
                    }.bind(this),1500);
                }
                price = Math.min(price,this.maxPrice);
            }
            this.giftcard.price = price;
        },
        parseMessage: function() {
            if(this.giftcard && this.giftcard.message && this.giftcard.message.length > 180){
                this.giftcard.message = this.giftcard.message.substring(0, 180);
            }
        },
        initCropper: function() {
            this.loadingMessage = 'editing';
            this.modalPicture = true;
            this.loading = false;
            var image = document.getElementById('picture-cropper');
            var that = this;
            this.pictureCropper = new Cropper(image, {
                aspectRatio: 1005 / 650,
                viewMode: 2,
                move: 'move',
                background: false,
                scalable: false,
                cropend: function(e) {
                    that.validateCropData();
                },
                ready: function(e) {
                    that.validateCropData();

                }
            });

        },
        validateCropData: function(){
            var cropData = this.pictureCropper.getData(true);
            this.quality = 'bad';
            if(cropData.width >= 504 && cropData.height >= 384){
                this.quality = 'low';
            }
            if(cropData.width >= 1005 && cropData.height >= 800){
                this.quality = 'good';
            }
            if(cropData.width >= 2010 && cropData.height >= 1600){
                this.quality = 'perfect';
            }
        },
        savePicture: function() {

            this.loadingMessage = 'cropping';
            this.loading = true;

            var cropData = this.pictureCropper.getData(true);

            $.ajax({
                url: siteroot+'/api/giftcards/'+this.uid+'/picture',
                method: 'PUT',
                data: cropData
            })
            .done(function(response) {
                this.modalPicture = false;
                this.giftcard.picture_link = this.giftcard.picture_link + '?v=' + Math.floor(Math.random() * 10000);
                this.loading = false;
                this.pictureCropper.destroy();
                this.pictureCropper = null;
                Vue.nextTick(function () {
                    this.sizePreview();
                }.bind(this));
            }.bind(this))
            .fail(function(err) {
                this.loading = false;
                if(callback){
                    callback(err);
                }
            }.bind(this))
        },
        sizePreview: function() {
            var maxWidth = $('#voucher-box').outerWidth();
            var voucherWidth = $('#voucher-preview').outerWidth();
            var voucherHeight = $('#voucher-preview').outerHeight();
            var scale = maxWidth / voucherWidth;

            $('#voucher-box').css('height',voucherHeight * scale);

            $('#voucher-preview').css({
                '-webkit-transform' : 'scale(' + scale + ')',
                '-moz-transform'    : 'scale(' + scale + ')',
                '-ms-transform'     : 'scale(' + scale + ')',
                '-o-transform'      : 'scale(' + scale + ')',
                'transform'         : 'scale(' + scale + ')'
            });
        },
        hasError: function(field){
            return this.validation.indexOf(field) !== -1
        },
        validateDesign: function() {
            this.validation = [];
            if(!this.giftcard.theme_id && !this.giftcard.picture){
                this.validation.push('picture');
            }
            if(!!!this.giftcard.message || this.giftcard.message.length > this.messageLimit){
                this.validation.push('message');
            }
            if(!!!this.giftcard.price){
                this.validation.push('price');
            }
            if(this.giftcard.price < this.minPrice){
                this.validation.push('price');
                this.showMin = true;
            }
            this.giftcard.price = Math.max(this.minPrice,this.giftcard.price);
            return !!!this.validation.length;
        },
        addToCart: function() {
            if(!this.validateSend()) return false;
            if(this.send == "false"){
                this.giftcard.sender_name = null;
                this.giftcard.recipient = null;
                this.giftcard.recipient_name = null;
                this.giftcard.send_at = null;
            }
            if(this.sendDelay == "true"){
                this.giftcard.send_at = this.year + '-' + ((parseInt(this.month) < 10) ? ("0" + this.month) : this.month) + '-' + ((parseInt(this.day) < 10) ? ("0" + this.day) : this.day);
            } else {
                this.giftcard.send_at = null;
            }
            this.loading = true;
            this.loadingMessage = 'cart';
            this.updateCard(function(err){
                if(!err){
                    $.ajax({
                        url: siteroot+'/api/giftcards/'+this.uid+'/confirm',
                        method: 'POST'
                    })
                    .done(function(response) {
                        window.location = checkoutUrl;
                    }.bind(this))
                    .fail(function(err) {
                        $.ajax({
                            url: siteroot+'/api/giftcards/'+this.uid+'/checkconfirmed',
                            method: 'POST'
                        })
                        .done(function(response) {
                            window.location = checkoutUrl;
                        }.bind(this))
                        .fail(function(err) {
                            this.loading = false;
                            parseError('giftcard_save')
                        }.bind(this));
                    }.bind(this));
                } else {
                    this.loading = false;
                    parseError('giftcard_save')
                }
            }.bind(this))
        },
        validateSend: function() {
            this.validation = [];
            if(this.send == "true" && (!this.giftcard.sender_name)){
                this.validation.push('sender_name');
            }
            if(this.send == "true" && (!this.giftcard.recipient || !this.validateEmail(this.giftcard.recipient))){
                this.validation.push('recipient');
            }
            if(this.send == "true" && (!this.giftcard.recipient_name)){
                this.validation.push('recipient_name');
            }
            if(this.sendDelay == "true" && (!this.day || !this.month || !this.year)){
                this.validation.push('date');
            }
            return !!!this.validation.length;
        },
        validateEmail: function(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validateDate: function(){
            if(this.date_days.indexOf(this.day) == -1) this.day = 1;
        }
    }
})

$(window).on('resize',function(){
    GiftCard.sizePreview();
})

var GiftFaq = new Vue({
    el: '#giftfaq',
    data: {
        open: null
    }
});
