var siteroot = (function(c) { for (var a = document.getElementsByTagName("meta"), b = 0;b < a.length;b++) {
    if (c == a[b].name || c == a[b].getAttribute("property")) { return a[b].content; } } return false;
})("siteroot")

var sitelocale = (function(c) { for (var a = document.getElementsByTagName("meta"), b = 0;b < a.length;b++) {
    if (c == a[b].name || c == a[b].getAttribute("property")) { return a[b].content; } } return false;
})("sitelocale")

var sitecontent = (function(c) { for (var a = document.getElementsByTagName("meta"), b = 0;b < a.length;b++) {
    if (c == a[b].name || c == a[b].getAttribute("property")) { return a[b].content; } } return false;
})("sitecontent")

$('document').ready(function(){
    $.ajaxSetup({ headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') } });
});

$(window).load(function(){
    stickyFooter();
    initCountdown();
    $('.box-title').matchHeight({
        byRow: false
    });
    $('.product-box').matchHeight({
        byRow: false
    });
});

$(window).resize(function(){
    stickyFooter();
});

$(window).scroll(function(){

});

//Detect old browsers
if (bowser.msie && bowser.version <= 9) {
    document.getElementById('upgrade-browser').style.display = 'block';
}

var countDownInterval;
var countDownData;
var countDownSeconds = 0;

function initCountdown(){

    if (typeof cart_expires_at !== 'undefined' && typeof cart_time_now !== 'undefined') {
        setTimeout(function(){
            $('#counter').css('right',0);
        }, 1000);
        countDownInterval = setInterval(countDown, 1000);
    }
}

function countDown(){
    countDownData = getTimeRemaining(cart_time_now,cart_expires_at);
    if(countDownData.total <= 0){
        //Expired
        clearInterval(countDownInterval);
        showModalExpired();
    } else {
        //Count down
        countDownSeconds++;
        $('#counter #time').html('<i class="fa fa-clock-o"></i> ' + pad(countDownData.minutes,2) + ':' + pad(countDownData.seconds,2));
    }
}

function stickyFooter(){
    if(typeof editVoucher == 'undefined'){
        var marginBottom = $(window).height() - $('body').height();

        if(marginBottom > 50){
            $('body').css('paddingBottom',marginBottom);
        } else {
            $('body').css('paddingBottom',70);
        }
    }
}

function showModalExpired(){
    var modal = UIkit.modal("#modal-expired",{center: true});
    modal.show();
}

//Helpers

function getTimeRemaining(starttime,endtime){
    var t = Date.parse(endtime) - (Date.parse(starttime) + (countDownSeconds * 1000));
    var seconds = Math.floor( (t/1000) % 60 );
    var minutes = Math.floor( (t/1000/60) % 60 );
    var hours = Math.floor( (t/(1000*60*60)) % 24 );
    var days = Math.floor( t/(1000*60*60*24) );
    return {
        'total': t,
        'days': days,
        'hours': hours,
        'minutes': minutes,
        'seconds': seconds
    };
}

function pad(num, size){
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

function parseError(error){
    UIkit.notify(eval("trans['"+sitelocale+"'].errors."+error), {
        pos:'bottom-right'
    });
}
