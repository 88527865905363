var trans = new Array();

trans['nl'] = {
    errors: {
        system: 'Er liep iets mis jammer genoeg. Probeer later opnieuw.',
        method_not_found: 'Er liep iets mis jammer genoeg. Probeer later opnieuw.',
        max_quantity: 'Je hebt het maximum aantal tickets bereikt.',
        out_of_stock: 'Er is geen extra voorraad beschikbaar van dit product.',
        giftcard_save: 'Er liep iets mis bij het bewaren van je cadeaubon.',
        max_file_size: 'De foto is te groot om te gebruiken. Max. 5 mb.',
        delete_file: 'Er liep iets mis bij het verwijderen van je foto.'
    },
};

trans['fr'] = {
    errors: {
        system: 'Désolé, une erreur est survenue. Essayez plus tard.',
        method_not_found: 'Une erreur est survenue.',
        max_quantity: 'Vous avez atteint le nombre de tickets maximum.',
        out_of_stock: 'Désolé, il n’y a plus de  tickets disponibles pour l’instant.',
        giftcard_save: 'Désolé, une erreur est survenue lors de l\'enregistrement de votre carte-cadeau.',
        max_file_size: 'La photo est trop grande pour être utilisée. Max 5 mb.',
        delete_file: 'Une erreur est survenue lors de la suppression de votre photo.'
    },
};
