$('.type-min').click(function(e){
    e.preventDefault();
    changeCartAmount($(this).parent().data('type'),'min');
});
$('.type-plus').click(function(e){
    e.preventDefault();
    changeCartAmount($(this).parent().data('type'),'plus');
});
$('.voucher-remove').click(function(e){
    e.preventDefault();
    removeVoucher($(this).parent().data('uid'),$(this).parent().data('item'));
});

var cartChanging = false;

function changeCartAmount(uid,method){
    if(!cartChanging){
        cartChanging = true;
        $('#type-'+uid+' .ticket-amount').addClass('loading');
        $.post( siteroot+"/cart/change", { uid: uid, method: method },function(){},"json")
        .done(function(data) {
            if(method == 'min'){
                var currentValue = $('#type-'+uid+' .ticket-amount').val();
                if(currentValue == 1){
                    disableTypeMin(uid);
                }
            }
            updateCart();
        })
        .error(function(data){
            cartChanging = false;
            if(typeof data.responseJSON !== 'undefined' && typeof data.responseJSON.error !== 'undefined'){
                parseError(data.responseJSON.error);
                console.log(data.responseJSON.error);
            } else {
                parseError('system');
            }
            $('#type-'+uid+' .ticket-amount').removeClass('loading');
        });
    }
}

function updateCart(){

    $.get( siteroot+"/cart/update",function(){},"json")
    .done(function(data) {
        parseCart(data.cart);
        cartChanging = false;
        $('.ticket-amount').removeClass('loading');
    })
    .error(function(data){
        cartChanging = false;
        $('.ticket-amount').removeClass('loading');
        if(typeof data.responseJSON !== 'undefined' && typeof data.responseJSON.error !== 'undefined'){
            parseError(data.responseJSON.error);
        } else {
            parseError('system');
        }
    });
}

function removeVoucher(uid,item){
    if(!cartChanging){
        cartChanging = true;
        $('#checkout-preloader').show();
        $.ajax({
            url: siteroot+'/api/giftcards/'+uid,
            method: 'DELETE'
        })
        .done(function(response) {
            cartChanging = false;
            $('#checkout-preloader').hide();
            $('#item-'+item).remove();
            updateCart();
        })
        .fail(function(err) {
            cartChanging = false;
            $('#checkout-preloader').hide();
            updateCart();
        });
    }
}

function parseCart(cart){

    var cartTotal = 0;

    if (typeof cart.items.types !== 'undefined' && cart.items.types.length > 0){
        for(i=0;i<cart.items.types.length;i++){
            cartTotal += cart.items.types[i].quantity;
            enableTypeMin(cart.items.types[i].id);
            $('#type-'+cart.items.types[i].id+' .ticket-amount').val(cart.items.types[i].quantity);
            if($('#type-'+cart.items.types[i].id).data('max') == cart.items.types[i].quantity){
                disableTypePlus(cart.items.types[i].id);
            } else {
                enableTypePlus(cart.items.types[i].id);
            }
        }
    }

    //Update total
    if(typeof cart.summary !== 'undefined' && typeof cart.summary.total !== 'undefined'){
        $('.cart-total').html(numberFormat(cart.summary.total));
    }
    //Update items
    $('.cart-items').html(cartTotal);
    if(cartTotal > 0){
        $('.cart-items').removeClass('hidden');
    } else {
        $('.cart-items').addClass('hidden');
    }

}

function disableTypeMin(uid){
    $('#type-'+uid+' .ticket-amount').val('0');
    $('#type-'+uid+' .type-min').addClass('btn-disabled');
}
function enableTypeMin(uid){
    $('#type-'+uid+' .type-min').removeClass('btn-disabled');
}
function enableTypePlus(uid){
    $('#type-'+uid+' .type-plus').removeClass('btn-disabled');
    $('#type-'+uid+' .type-plus').addClass('btn-success');
}
function disableTypePlus(uid){
    $('#type-'+uid+' .type-plus').removeClass('btn-success');
    $('#type-'+uid+' .type-plus').addClass('btn-disabled');
}

function numberFormat(number){
    var number = number.toFixed(2) + '';
    return '€ ' + number.replace(".", ",");
}
